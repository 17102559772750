import Head from 'next/head';
import { useRouter } from 'next/router';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { type HTMLProps, type ReactNode } from 'react';

import ModalView from '@/components/common/ModalView';
import Footer from '@/components/footer/Footer';
import Header from '@/components/headers/Header';
import ChatwootWidget from '@/components/widgets/ChatwootWidget';
import MoveToTop from '@/components/widgets/MoveToTop';

declare global {
  const ZaloSocialSDK: {
    reload: () => void;
  };
  interface Window {
    FB: any;
    $chatwoot: any;
    chatwootSettings: any;
    chatwootSDK: any;
    gtag: any;
  }
}

export interface ILandingPageTemplateProps extends HTMLProps<HTMLDivElement> {
  seo?: NextSeoProps;
  customHeader?: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideMoveToTop?: boolean;
  hideZaloWidget?: boolean;
  hideChatwootWidget?: boolean;
}

export default function LandingPageTemplate({
  seo,
  customHeader,
  hideHeader,
  hideFooter,
  hideMoveToTop,
  hideChatwootWidget,
  children,
}: ILandingPageTemplateProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href={`${router.basePath}/android-chrome-512x512.png`}
          key="icon512"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${router.basePath}/android-chrome-192x192.png`}
          key="icon192"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
      </Head>
      <NextSeo
        {...{
          ...{
            ...seo,
            openGraph: { ...seo?.openGraph, siteName: 'Zump' },
          },
          defaultTitle: 'Zump',
          // languageAlternates: [
          //   {
          //     hrefLang: 'en',
          //     href: `${process.env.NEXT_PUBLIC_BASE_URL}en/`,
          //   },
          //   {
          //     hrefLang: 'vi',
          //     href: `${process.env.NEXT_PUBLIC_BASE_URL}vi/`,
          //   },
          // ],
          additionalMetaTags: [
            {
              name: 'mobile-web-app-capable',
              content: 'yes',
            },
            {
              name: 'apple-mobile-web-app-capable',
              content: 'yes',
            },
            {
              name: 'keywords',
              content:
                'Zump, Zump.vn, Zump FnB, quản lý quán ăn, quản lý quán cà phê, quản lý nhà hàng, quản lý cửa hàng, quán lý bán hàng, phục vụ quán ăn',
            },
            {
              name: 'google',
              content: 'nositelinkssearchbox',
              keyOverride: 'sitelinks',
            },
            {
              name: 'google',
              content: 'notranslate',
              keyOverride: 'notranslate',
            },
          ],
          twitter: {
            cardType: 'summary_large_image',
          },
        }}
      />
      <main className="box-border w-full bg-transparent max-md:select-none">
        {!hideHeader && <>{customHeader || <Header />}</>}
        <div className="flex min-h-screen flex-col overscroll-none">
          {children}
        </div>
        <div className="fixed bottom-24 right-2 z-10 flex flex-col gap-3 md:right-5">
          {/* <FacebookWidget />
          <ZaloWidget /> */}
          {!hideChatwootWidget && <ChatwootWidget />}
          {!hideMoveToTop && <MoveToTop />}
        </div>
        {!hideFooter && <Footer />}
        <ModalView />
      </main>
    </>
  );
}
